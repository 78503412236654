import React from "react";
import styled from "styled-components";

const PrivacyWrapper = styled.section`
    background: #1e1e1e;
    color: #e0e0e0;
    padding: 50px 20px;
`;

const PrivacyContainer = styled.div`
    max-width: 900px;
    margin: 0 auto;
`;

const PrivacyTitle = styled.h1`
    font-size: 32px;
    font-weight: bold;
    color: #ffa500;
    margin-bottom: 20px;
    text-align: center;
`;

const PrivacyText = styled.p`
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
`;

const PrivacyList = styled.ul`
    margin: 20px 0;
    padding-left: 20px;
`;

const PrivacyListItem = styled.li`
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 10px;
`;

const PrivacyPolicy = () => {
    return (
        <PrivacyWrapper>
            <PrivacyContainer>
                <PrivacyTitle>Politique de Confidentialité de Corpohost AI</PrivacyTitle>
                <PrivacyText>
                    Chez **Corpohost AI**, nous attachons une grande importance à la protection de vos données personnelles. Cette politique de confidentialité décrit comment nous collectons, utilisons et protégeons vos informations lorsque vous utilisez notre plateforme.
                </PrivacyText>

                <PrivacyTitle>1. Collecte des Informations</PrivacyTitle>
                <PrivacyText>
                    Nous collectons diverses informations personnelles lorsque vous utilisez nos services, notamment :
                </PrivacyText>
                <PrivacyList>
                    <PrivacyListItem>Votre nom et prénom</PrivacyListItem>
                    <PrivacyListItem>Votre adresse e-mail</PrivacyListItem>
                    <PrivacyListItem>Votre numéro de téléphone</PrivacyListItem>
                    <PrivacyListItem>Des informations techniques comme votre adresse IP et le type d’appareil utilisé</PrivacyListItem>
                </PrivacyList>

                <PrivacyTitle>2. Utilisation des Données</PrivacyTitle>
                <PrivacyText>
                    Vos données sont utilisées pour :
                </PrivacyText>
                <PrivacyList>
                    <PrivacyListItem>Fournir et améliorer nos services</PrivacyListItem>
                    <PrivacyListItem>Vous contacter en cas de besoin</PrivacyListItem>
                    <PrivacyListItem>Assurer la sécurité et le bon fonctionnement de la plateforme</PrivacyListItem>
                    <PrivacyListItem>Vous envoyer des mises à jour et des informations pertinentes (avec votre consentement)</PrivacyListItem>
                </PrivacyList>

                <PrivacyTitle>3. Partage des Données</PrivacyTitle>
                <PrivacyText>
                    Nous ne vendons ni ne louons vos données personnelles. Cependant, nous pouvons les partager avec des partenaires de confiance uniquement pour :
                </PrivacyText>
                <PrivacyList>
                    <PrivacyListItem>Respecter une obligation légale</PrivacyListItem>
                    <PrivacyListItem>Améliorer nos services grâce à des outils d’analyse</PrivacyListItem>
                    <PrivacyListItem>Assurer la sécurité de notre plateforme</PrivacyListItem>
                </PrivacyList>

                <PrivacyTitle>4. Sécurité des Données</PrivacyTitle>
                <PrivacyText>
                    Nous mettons en place des mesures de sécurité strictes pour protéger vos informations contre l'accès non autorisé, la modification ou la divulgation.
                </PrivacyText>

                <PrivacyTitle>5. Vos Droits</PrivacyTitle>
                <PrivacyText>
                    Vous avez le droit de :
                </PrivacyText>
                <PrivacyList>
                    <PrivacyListItem>Accéder à vos données personnelles</PrivacyListItem>
                    <PrivacyListItem>Demander la correction ou la suppression de vos informations</PrivacyListItem>
                    <PrivacyListItem>Retirer votre consentement à tout moment</PrivacyListItem>
                    <PrivacyListItem>Déposer une plainte auprès d’une autorité de protection des données</PrivacyListItem>
                </PrivacyList>

                <PrivacyTitle>6. Cookies</PrivacyTitle>
                <PrivacyText>
                    Nous utilisons des cookies pour améliorer votre expérience sur notre plateforme. Vous pouvez modifier vos préférences en matière de cookies à tout moment dans les paramètres de votre navigateur.
                </PrivacyText>

                <PrivacyTitle>7. Modifications de la Politique</PrivacyTitle>
                <PrivacyText>
                    Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page.
                </PrivacyText>

                <PrivacyTitle>8. Contact</PrivacyTitle>
                <PrivacyText>
                    Pour toute question concernant notre politique de confidentialité, vous pouvez nous contacter à :
                </PrivacyText>
                <PrivacyText>
                    📧 Email : contact@corpohostai.fr
                </PrivacyText>
                <PrivacyText>
                    📍 Adresse : 15 Rue de l'Intelligence Artificielle, 75000 Paris, France
                </PrivacyText>
            </PrivacyContainer>
        </PrivacyWrapper>
    );
};

export default PrivacyPolicy;