import styled from "styled-components";

const StyledContainer = styled.section`
    width: 100%;
    padding: 40px 10px;
    background: linear-gradient(135deg, #1a1a1a, #333333);
    box-sizing: border-box;
`;

const StyledBlock = styled.div`
    max-width: 700px;
    margin: 30px auto;
    padding: 30px 20px;
    background-color: #444444;
    border-radius: 10px;
    box-shadow: 0 6px 10px rgba(255, 255, 255, 0.1);
`;

const StyledHeading = styled.h1`
    text-align: center;
    font-size: 32px;
    margin-bottom: 15px;
    color: #00ccff;
    font-weight: bold;
`;

const StyledText = styled.div`
    font-size: 16px;
    line-height: 1.5;
    color: #ffffff;

    p {
        margin-bottom: 15px;
    }

    a {
        color: #00ccff;
        text-decoration: none;
        font-weight: 500;
    }

    a:hover {
        text-decoration: underline;
    }
`;

const CookiePolicy = () => {
  return (
    <StyledContainer>
      <StyledBlock>
        <StyledHeading>Avis sur Corpohost AI</StyledHeading>
        <StyledText>
          <p>
            Chez Corpohost AI, nous plaçons la protection de votre vie privée au cœur de notre mission. Cet avis explique comment nous collectons, utilisons et sécurisons vos informations lors de l'utilisation de notre plateforme d'intelligence artificielle.
          </p>
          <p>
            Nous pouvons employer des cookies et des technologies similaires pour optimiser votre expérience, analyser le trafic du site et personnaliser le contenu selon vos préférences. En poursuivant votre navigation sur notre plateforme, vous acceptez notre avis sur la protection des données.
          </p>
          <p>
            Vous avez la possibilité de gérer ou de supprimer les cookies via les paramètres de votre navigateur, bien que cela puisse influencer les fonctionnalités et la personnalisation de notre service.
          </p>
          <p>
            Pour plus d'informations, veuillez consulter notre{" "}
            <a href="/privacy" target="_blank" rel="noopener noreferrer">
              Avis sur la Protection des Données
            </a>.
          </p>
        </StyledText>
      </StyledBlock>
    </StyledContainer>
  );
};
export default CookiePolicy;