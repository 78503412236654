import CountUp from "react-countup";
import styled from "styled-components";
import { FaRobot, FaBrain, FaUsers } from "react-icons/fa";

const NouvelleSection = styled.section`
    background: linear-gradient(90deg, #212121, #424242);
    padding: 50px 30px;
    color: #ffffff;
`;

const NouveauTitre = styled.h2`
    text-align: center;
    font-size: 36px;
    margin-bottom: 50px;
    color: #bbdefb;
    font-weight: bold;
`;

const NouveauConteneurGrille = styled.div`
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
`;

const NouvelleBoite = styled.div`
    background: #263238;
    padding: 40px 30px;
    border-radius: 16px;
    text-align: center;
    box-shadow: 0 8px 12px rgba(255, 255, 255, 0.1);
    transition: transform 0.4s ease, box-shadow 0.4s ease;

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 10px 20px rgba(255, 255, 255, 0.2);
    }
`;

const NouveauConteneurIcône = styled.div`
    font-size: 42px;
    margin-bottom: 15px;
    color: #bbdefb;
`;

const NouveauTexteNombre = styled.div`
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #bbdefb;
`;

const NouvelleDescription = styled.div`
    font-size: 20px;
    color: #ffffff;
`;

const PourquoiNousChoisir = () => {
  return (
    <NouvelleSection>
      <NouveauTitre>Qu'est-ce qui nous distingue ?</NouveauTitre>
      <NouveauConteneurGrille>
        <NouvelleBoite>
          <NouveauConteneurIcône>
            <FaRobot />
          </NouveauConteneurIcône>
          <NouveauTexteNombre>
            <CountUp end={56789} duration={3} separator="," />
          </NouveauTexteNombre>
          <NouvelleDescription>Projets Réalisés</NouvelleDescription>
        </NouvelleBoite>
        <NouvelleBoite>
          <NouveauConteneurIcône>
            <FaBrain />
          </NouveauConteneurIcône>
          <NouveauTexteNombre>
            <CountUp end={92} duration={3} separator="," />
          </NouveauTexteNombre>
          <NouvelleDescription>Technologies Avancées</NouvelleDescription>
        </NouvelleBoite>
        <NouvelleBoite>
          <NouveauConteneurIcône>
            <FaUsers />
          </NouveauConteneurIcône>
          <NouveauTexteNombre>
            <CountUp end={9876} duration={3} separator="," />
          </NouveauTexteNombre>
          <NouvelleDescription>Utilisateurs Satisfaits</NouvelleDescription>
        </NouvelleBoite>
      </NouveauConteneurGrille>
    </NouvelleSection>
  );
};
export default PourquoiNousChoisir;