import styled from "styled-components";
import LocationBlock from "../Components/BloqueUbicacion";

const StyledSection = styled.section`
    width: 100%;
    padding: 100px 30px;
    background: linear-gradient(135deg, #2c3e50, #34495e);
    box-sizing: border-box;
`;

const StyledContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    align-items: flex-start;
`;

const StyledInfoSection = styled.div`
    flex: 1;
    min-width: 350px;
    padding: 30px;
    background: #1c1c1c;
    border-radius: 20px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
`;

const StyledFormSection = styled.div`
    flex: 1;
    min-width: 350px;
    padding: 30px;
    background: #1c1c1c;
    border-radius: 20px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
`;

const StyledHeading = styled.h1`
    font-size: 40px;
    color: #ecf0f1;
    text-align: center;
    margin-bottom: 50px;
    font-weight: normal;
`;

const StyledSectionHeading = styled.h2`
    font-size: 32px;
    color: #ecf0f1;
    margin-bottom: 25px;
    text-align: center;
`;

const StyledInfoText = styled.p`
    font-size: 20px;
    color: #bdc3c7;
    margin-bottom: 20px;
    text-align: center;
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 25px;
`;

const StyledInput = styled.input`
  padding: 16px;
  border: 1px solid #7f8c8d;
  border-radius: 8px;
  font-size: 18px;
  transition: border-color 0.3s;

  &:focus {
    border-color: #ecf0f1;
    outline: none;
  }
`;

const StyledTextarea = styled.textarea`
  padding: 16px;
  border: 1px solid #7f8c8d;
  border-radius: 8px;
  font-size: 18px;
  resize: vertical;
  min-height: 180px;
  transition: border-color 0.3s;

  &:focus {
    border-color: #ecf0f1;
    outline: none;
  }
`;

const StyledButton = styled.button`
  padding: 16px;
  background-color: #ecf0f1;
  color: #2c3e50;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #1abc9c;
  }
`;

const Contact = () => {
  return (
    <StyledSection>
      <StyledHeading>Contactez Corpohost AI</StyledHeading>
      <StyledContainer>
        <StyledInfoSection>
          <StyledSectionHeading>Informations de Corpohost AI</StyledSectionHeading>
          <StyledInfoText>
            <strong>Téléphone :</strong> +33 1 23 45 67 89
          </StyledInfoText>
          <StyledInfoText>
            <strong>Email :</strong> contact@corpohostai.fr
          </StyledInfoText>
          <StyledInfoText>
            <strong>Adresse :</strong> Rue Imaginaire, 123, Paris, France
          </StyledInfoText>
        </StyledInfoSection>
        <StyledFormSection>
          <StyledSectionHeading>Envoyez Nous un Message</StyledSectionHeading>
          <StyledForm action="thanks/index.php" method="post">
            <StyledInput type="text" name="name" placeholder="Votre nom" required />
            <StyledInput type="email" name="email" placeholder="Votre email" required />
            <StyledInput type="text" name="subject" placeholder="Sujet" required />
            <StyledTextarea name="message" placeholder="Écrivez votre message ici..." required />
            <StyledButton type="submit">Envoyer le Message</StyledButton>
          </StyledForm>
        </StyledFormSection>
      </StyledContainer>
      <div style={{ marginTop: "50px" }}>

      </div>
    </StyledSection>
  );
};
export default Contact;