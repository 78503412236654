import styled from "styled-components";

const NouveauWrapper = styled.section`
  width: 100%;
  padding: 50px 15px;
  background: linear-gradient(45deg, #333333, #444444);
  box-sizing: border-box;
`;

const NouveauContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
`;

const NouveauInfo = styled.div`
  flex: 1;
  min-width: 250px;
  text-align: center;
`;

const NouveauImage = styled.img`
    max-width: 100%;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(255, 255, 255, 0.3);
`;

const NouveauText = styled.div`
    flex: 1;
    min-width: 250px;
`;

const NouveauHeader = styled.h2`
    font-size: 32px;
    color: #ffcc00;
    margin-bottom: 15px;
    font-weight: bold;
`;

const NouveauParagraph = styled.p`
    font-size: 16px;
    color: #e0e0e0;
    line-height: 1.6;
    margin-bottom: 15px;
`;

const About = () => {
  return (
    <NouveauWrapper>
      <NouveauContainer>
        <NouveauText>
          <NouveauHeader>À Propos de Corpohost AI</NouveauHeader>
          <NouveauParagraph>
            Chez Corpohost AI, nous nous consacrons à transformer l'avenir avec des solutions intelligentes. Basés à Paris, nous sommes à la pointe de l'innovation technologique et du développement de l'intelligence artificielle pour améliorer les performances et favoriser la croissance.
          </NouveauParagraph>
          <NouveauParagraph>
            Notre équipe passionnée travaille avec détermination et créativité, anticipant les défis futurs et proposant des technologies avancées qui ouvrent de nouvelles perspectives pour les entreprises. Venez découvrir un univers riche en opportunités !
          </NouveauParagraph>
        </NouveauText>
      </NouveauContainer>
    </NouveauWrapper>
  );
};
export default About;