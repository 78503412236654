import React from "react";
import styled from "styled-components";

const CustomFooter = styled.footer`
  background: linear-gradient(90deg, #212121, #424242);
  color: #e0e0e0;
  padding: 60px 25px 40px;
  box-sizing: border-box;
  border-top: 2px solid rgba(255, 255, 255, 0.5);
`;

const CustomFooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
`;

const CustomSection = styled.div`
  flex: 1;
  min-width: 220px;
`;

const CustomSectionTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 600;
  color: #FFAB00;
`;

const CustomText = styled.p`
  font-size: 15px;
  margin-bottom: 8px;
  line-height: 1.5;
`;

const CustomFooterLink = styled.a`
  color: #e0e0e0;
  text-decoration: none;
  font-size: 15px;
  display: block;
  margin-bottom: 8px;
  transition: color 0.3s;

  &:hover {
    color: #FFAB00;
  }
`;

const CustomFooterBottom = styled.div`
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  padding-top: 20px;
  font-size: 13px;
  margin-top: 25px;
`;

const Footer = () => {
  return (
      <CustomFooter>
        <CustomFooterContent>
          <CustomSection>
            <CustomSectionTitle>Corpohost AI</CustomSectionTitle>
            <CustomText>Adresse : 15 Rue de l'Intelligence, 75000 Paris, France</CustomText>
            <CustomText>Téléphone : +33 1 23 45 67 89</CustomText>
            <CustomText>Email : contact@corpohostai.fr</CustomText>
          </CustomSection>
          <CustomSection>
            <CustomSectionTitle>Liens</CustomSectionTitle>
            <CustomFooterLink href="/" target="_blank" rel="noopener noreferrer">
              Accueil
            </CustomFooterLink>
            <CustomFooterLink href="/about" target="_blank" rel="noopener noreferrer">
              À propos
            </CustomFooterLink>
            <CustomFooterLink href="/contact" target="_blank" rel="noopener noreferrer">
              Contact
            </CustomFooterLink>
            <CustomFooterLink href="/policy" target="_blank" rel="noopener noreferrer">
              Politique de confidentialité
            </CustomFooterLink>
          </CustomSection>
          <CustomSection>
            <CustomSectionTitle>Suivez-nous</CustomSectionTitle>
            <CustomFooterLink
                href="https://www.facebook.com/CorpohostAI"
                target="_blank"
                rel="noopener noreferrer"
            >
              Facebook
            </CustomFooterLink>
            <CustomFooterLink
                href="https://twitter.com/CorpohostAI"
                target="_blank"
                rel="noopener noreferrer"
            >
              Twitter
            </CustomFooterLink>
            <CustomFooterLink
                href="https://www.linkedin.com/company/CorpohostAI"
                target="_blank"
                rel="noopener noreferrer"
            >
              LinkedIn
            </CustomFooterLink>
            <CustomFooterLink
                href="https://www.instagram.com/CorpohostAI"
                target="_blank"
                rel="noopener noreferrer"
            >
              Instagram
            </CustomFooterLink>
          </CustomSection>
        </CustomFooterContent>
        <CustomFooterBottom>
          &copy; {new Date().getFullYear()} Corpohost AI. Tous droits réservés.
        </CustomFooterBottom>
      </CustomFooter>
  );
};

export default Footer;