import styled from "styled-components";

const NewContainer = styled.section`
    background: linear-gradient(135deg, #1a1a1a, #333333);
    padding: 40px 20px;
    color: #e0e0e0;
`;

const NewCardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
`;

const NewCard = styled.div`
    flex: 1;
    min-width: 250px;
    max-width: 280px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-6px);
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.7);
    }
`;

const NewCardImage = styled.img`
    width: 100%;
    height: 180px;
    object-fit: cover;
`;

const NewCardContent = styled.div`
    padding: 15px;
    text-align: center;
`;

const NewCardTitle = styled.h3`
    font-size: 24px;
    margin-bottom: 10px;
    color: #ffcc00;
`;

const NewCardDescription = styled.p`
    font-size: 16px;
    line-height: 1.4;
    color: #b0b0b0;
`;

const PrivilegesInfinity = () => {
  return (
    <NewContainer>
      <NewCardsContainer>
        <NewCard>
          <NewCardImage src="/reacti3.jpg" alt="Innovation Continue" />
          <NewCardContent>
            <NewCardTitle>Idées Créatives</NewCardTitle>
            <NewCardDescription>
              Adoptez un flux constant d'idées innovantes qui révolutionnent vos pratiques professionnelles.
            </NewCardDescription>
          </NewCardContent>
        </NewCard>
        <NewCard>
          <NewCardImage src="/reacti2.jpg" alt="Solutions Intégrées" />
          <NewCardContent>
            <NewCardTitle>Solutions Complètes</NewCardTitle>
            <NewCardDescription>
              Explorez une gamme complète de services technologiques pour optimiser tous les aspects de votre organisation.
            </NewCardDescription>
          </NewCardContent>
        </NewCard>
        <NewCard>
          <NewCardImage src="/reacti1.jpg" alt="Experts Technologiques" />
          <NewCardContent>
            <NewCardTitle>Spécialistes Technologiques</NewCardTitle>
            <NewCardDescription>
              Notre équipe d'experts adapte des stratégies pour conduire efficacement votre évolution numérique.
            </NewCardDescription>
          </NewCardContent>
        </NewCard>
      </NewCardsContainer>
    </NewContainer>
  );
};
export default PrivilegesInfinity;