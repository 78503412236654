import React from "react";
import styled from "styled-components";

const Wrapper = styled.section`
    width: 100%;
    padding: 40px 20px;
    background-color: #1c1c1c;
    box-sizing: border-box;
`;

const Heading = styled.h2`
    text-align: center;
    font-size: 30px;
    margin-bottom: 30px;
    color: #e0e0e0;
    font-weight: bold;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    justify-content: center;
`;

const Card = styled.div`
    background-color: #2c2c2c;
    border-radius: 8px;
    overflow: hidden;
    max-width: 320px;
    flex: 1;
    min-width: 280px;
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-6px);
        box-shadow: 0 6px 10px rgba(255, 255, 255, 0.15);
    }
`;

const Image = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
`;

const Content = styled.div`
    padding: 20px;
`;

const Title = styled.h3`
    font-size: 20px;
    margin-bottom: 10px;
    color: #e0e0e0;
    font-weight: 500;
`;

const Date = styled.p`
    font-size: 14px;
    color: #aaa;
    margin-bottom: 10px;
`;

const Excerpt = styled.p`
    font-size: 16px;
    color: #ccc;
    line-height: 1.5;
    margin-bottom: 15px;
`;

const Button = styled.a`
    display: inline-block;
    padding: 10px 20px;
    background-color: #e0e0e0;
    color: #1c1c1c;
    text-decoration: none;
    border-radius: 4px;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #ccc;
    }
`;

const posts = [
  {
    image: "blog1.jpg",
    title: "Lancement de la plateforme Corpohost AI",
    date: "15 Avril 2025",
    excerpt:
      "Corpohost AI présente sa nouvelle plateforme, conçue pour révolutionner l'expérience utilisateur et optimiser les processus.",
    link: "#"
  },
  {
    image: "blog2.jpg",
    title: "Innovations en IA",
    date: "10 Avril 2025",
    excerpt:
      "Découvrez comment nos dernières innovations en IA transforment le fonctionnement des entreprises à l'ère numérique.",
    link: "#"
  },
  {
    image: "blog3.jpg",
    title: "Partenariats stratégiques pour l'avenir",
    date: "5 Avril 2025",
    excerpt:
      "Nous avons établi de nouveaux partenariats stratégiques qui renforceront notre avenir et élargiront les opportunités dans le secteur technologique.",
    link: "#"
  }
];

const BlogComponent = () => {
  return (
    <Wrapper>
      <Heading>Dernières nouvelles</Heading>
      <Grid>
        {posts.map((post, index) => (
          <Card key={index}>
            <Image src={post.image} alt={post.title} />
            <Content>
              <Title>{post.title}</Title>
              <Date>{post.date}</Date>
              <Excerpt>{post.excerpt}</Excerpt>
              <Button href={post.link}>En savoir plus</Button>
            </Content>
          </Card>
        ))}
      </Grid>
    </Wrapper>
  );
};

export default BlogComponent;