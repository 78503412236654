import React from "react";
import styled from "styled-components";

const Wrapper = styled.section`
    width: 100%;
    padding: 60px 20px;
    background: linear-gradient(90deg, #1a1a1a, #333333);
    box-sizing: border-box;
    color: #e0e0e0;
`;

const Heading = styled.h2`
    text-align: center;
    font-size: 34px;
    margin-bottom: 50px;
    color: #ffffff;
    font-weight: bold;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    justify-items: center;
`;

const Card = styled.div`
    background: rgba(50, 50, 50, 0.9);
    padding: 30px 20px;
    border-radius: 12px;
    width: 100%;
    max-width: 320px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-8px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
    }
`;

const Photo = styled.img`
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto 15px auto;
    display: block;
    border: 3px solid #444444;
`;

const Name = styled.h3`
    font-size: 20px;
    margin-bottom: 5px;
    color: #ffffff;
    text-align: center;
`;

const Position = styled.p`
    font-size: 14px;
    margin-bottom: 15px;
    color: #e0e0e0;
    text-align: center;
    opacity: 0.8;
`;

const Text = styled.p`
    font-size: 16px;
    line-height: 1.5;
    color: #e0e0e0;
    text-align: center;
`;

const data = [
  {
    photo: "test1.jpg",
    name: "Sofia Dupont",
    position: "PDG, Corpohost AI",
    review:
      "Corpohost AI a véritablement transformé notre façon de travailler. Leur approche humaine et professionnelle a eu un impact positif sur notre succès."
  },
  {
    photo: "test3.jpg",
    name: "Carlos Lefevre",
    position: "CTO, Corpohost AI",
    review:
      "La créativité et l'engagement de l'équipe Corpohost AI font toute la différence. Leurs solutions innovantes nous ont permis de surmonter des défis complexes."
  },
  {
    photo: "test2.jpg",
    name: "Elena Martin",
    position: "Directrice Marketing, Corpohost AI",
    review:
      "Collaborer avec Corpohost AI a été une expérience enrichissante. Leur attention personnalisée et leur expertise ont propulsé notre stratégie."
  }
];

const Testimonials = () => {
  return (
    <Wrapper>
      <Heading>Témoignages des utilisateurs</Heading>
      <Grid>
        {data.map((item, index) => (
          <Card key={index}>
            <Photo src={item.photo} alt={item.name} />
            <Name>{item.name}</Name>
            <Position>{item.position}</Position>
            <Text>{item.review}</Text>
          </Card>
        ))}
      </Grid>
    </Wrapper>
  );
};

export default Testimonials;