import styled from "styled-components";

const NewWrapper = styled.div`
    max-width: 900px;
    margin: 60px auto 30px;
    padding: 30px 20px;
    background-color: #121212;
    border-radius: 15px;
    box-shadow: 0 8px 12px rgba(255, 255, 255, 0.1);
`;

const NewHeaderText = styled.h1`
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;
    color: #e91e63;
    font-weight: bold;
`;

const NewTextContent = styled.div`
    font-size: 20px;
    line-height: 1.8;
    color: #ffffff;

    p {
        margin-bottom: 15px;
    }

    strong {
        color: #e91e63;
    }
`;

const TermsAndConditions = () => {
  return (
    <>
      <NewWrapper>
        <NewHeaderText>Conditions Générales d'Utilisation de Corpohost AI</NewHeaderText>
        <NewTextContent>
          <p>
            Bienvenue sur la plateforme Corpohost AI. En accédant à notre site, vous acceptez ces conditions générales d'utilisation, qui peuvent être mises à jour à tout moment sans préavis. Nous vous conseillons de les consulter régulièrement pour rester informé.
          </p>
          <p>
            <strong>1. Utilisation de la plateforme :</strong> L'utilisateur s'engage à utiliser la plateforme de manière éthique et conformément à la législation en vigueur. Il est interdit d'utiliser la plateforme pour des activités pouvant compromettre la sécurité ou l'intégrité des informations.
          </p>
          <p>
            <strong>2. Droits de propriété :</strong> Tout le contenu, y compris les textes, images, logos et logiciels, est la propriété exclusive de Corpohost AI et est protégé par les lois sur la propriété intellectuelle.
          </p>
          <p>
            <strong>3. Limitation de responsabilité :</strong> Corpohost AI n'est pas responsable des dommages directs, indirects ou consécutifs résultant de l'utilisation ou de l'incapacité à utiliser la plateforme.
          </p>
          <p>
            <strong>4. Mises à jour et modifications :</strong> Nous nous réservons le droit de modifier, mettre à jour ou supprimer toute partie de ces conditions à tout moment sans préavis. L'utilisation continue de la plateforme implique l'acceptation de ces modifications.
          </p>
          <p>
            <strong>5. Législation et juridiction :</strong> Ces conditions seront régies et interprétées conformément à la loi française. En cas de litige, les deux parties se soumettront à la juridiction exclusive des tribunaux compétents.
          </p>
          <p>
            Si vous avez des questions ou si vous avez besoin de plus d'informations, n'hésitez pas à nous contacter.
          </p>
        </NewTextContent>
      </NewWrapper>
    </>
  );
};
export default TermsAndConditions;