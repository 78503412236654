import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const HeaderWrapper = styled.header`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: ${({ isScrolled }) =>
      isScrolled ? "linear-gradient(90deg, #2c3e50, #34495e)" : "#34495e"};
  box-shadow: ${({ isScrolled }) =>
      isScrolled ? "0 2px 8px rgba(0, 0, 0, 0.5)" : "none"};
  transition: background 0.3s, box-shadow 0.3s;
  padding: 20px 25px;
`;

const HeaderContainer = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const LogoIcon = styled.div`
  width: 50px;
  height: 50px;
  background-color: #8e44ad;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;

const LogoText = styled.span`
  font-size: 28px;
  font-weight: 700;
  color: #ecf0f1;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 25px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  font-size: 20px;
  color: #ecf0f1;
  font-weight: 600;
  transition: color 0.2s;

  &:hover {
    color: #8e44ad;
  }
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const Bar = styled.span`
  height: 5px;
  width: 35px;
  background-color: #ecf0f1;
  margin: 4px 30px;
`;

const MobileMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 35px;
  background: #2c3e50;
  border-radius: 6px;
  overflow: hidden;
  display: ${({ open }) => (open ? "block" : "none")};
  width: 260px;

  @media (min-width: 769px) {
    display: none;
  }
`;

const MobileMenuItem = styled(Link)`
  display: block;
  padding: 14px 18px;
  text-decoration: none;
  color: #ecf0f1;
  border-bottom: 1px solid #34495e;
  transition: background-color 0.2s;

  &:hover {
    background-color: #34495e;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const onScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
      <HeaderWrapper isScrolled={isScrolled}>
        <HeaderContainer>
          <LogoLink to="/">
            <LogoIcon>
              <span style={{ color: "#ecf0f1", fontSize: "28px", fontWeight: "bold" }}>🤖</span>
            </LogoIcon>
            <LogoText>Corpohost AI</LogoText>
          </LogoLink>
          <Nav>
            <NavLink to="/" onClick={handleLinkClick}>
              Accueil
            </NavLink>
            <NavLink to="/about" onClick={handleLinkClick}>
              À propos
            </NavLink>
            <NavLink to="/contact" onClick={handleLinkClick}>
              Contact
            </NavLink>
          </Nav>
          <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <Bar />
            <Bar />
            <Bar />
          </Hamburger>
          <MobileMenu open={isMenuOpen}>
            <MobileMenuItem to="/" onClick={handleLinkClick}>
              Accueil
            </MobileMenuItem>
            <MobileMenuItem to="/about" onClick={handleLinkClick}>
              À propos
            </MobileMenuItem>
            <MobileMenuItem to="/contact" onClick={handleLinkClick}>
              Contact
            </MobileMenuItem>
          </MobileMenu>
        </HeaderContainer>
      </HeaderWrapper>
  );
};

export default Header;