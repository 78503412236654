import React, { useState } from "react";
import styled from "styled-components";

const SectionSombre = styled.section`
    width: 100%;
    padding: 30px;
    background-color: #1a1a1a;
    box-sizing: border-box;
`;

const ConteneurSombre = styled.div`
    max-width: 600px;
    margin: 30px auto;
`;

const ÉlémentSombre = styled.div`
    border-bottom: 1px solid #444444;
    margin-bottom: 12px;
`;

const TitreSombre = styled.h3`
    margin: 0;
    padding: 15px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333333;
    color: #ffffff;
    border-radius: 6px;
`;

const TexteSombre = styled.div`
    padding: 15px;
    font-size: 16px;
    color: #ffffff;
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    background-color: #222222;
    border-radius: 0 0 6px 6px;
`;

const FAQ = () => {
  const faqItems = [
    {
      question: "Qu'est-ce que Corpohost AI et comment ça fonctionne ?",
      answer:
        "Corpohost AI est une plateforme innovante qui utilise l'intelligence artificielle pour améliorer les processus opérationnels et stimuler la créativité au sein des équipes."
    },
    {
      question: "Quels sont les bénéfices de rejoindre Corpohost AI ?",
      answer:
        "En rejoignant Corpohost AI, vous aurez accès à des outils avancés et ferez partie d'une communauté dynamique qui favorise l'innovation et le développement personnel."
    },
    {
      question: "Comment puis-je m'inscrire sur Corpohost AI ?",
      answer:
        "L'inscription est simple : remplissez le formulaire sur notre site et suivez les étapes pour activer votre compte instantanément."
    },
    {
      question: "Quels types de projets Corpohost AI développe-t-il ?",
      answer:
        "Nous développons des projets centrés sur l'intelligence artificielle, l'automatisation et la transformation numérique, visant à transformer les modes de fonctionnement des entreprises."
    },
    {
      question: "Ai-je besoin d'une expérience préalable pour participer ?",
      answer:
        "Aucune expérience requise. Corpohost AI est accessible à tous, offrant des ressources et un soutien pour chaque participant."
    },
    {
      question: "Où puis-je obtenir plus d'informations ?",
      answer:
        "Pour plus de détails, consultez notre site ou contactez notre équipe d'assistance, qui se fera un plaisir de répondre à vos questions."
    }
  ];

  const [openItems, setOpenItems] = useState(Array(faqItems.length).fill(false));

  const toggleItem = (index) => {
    setOpenItems((prev) => {
      const newOpenItems = [...prev];
      newOpenItems[index] = !newOpenItems[index];
      return newOpenItems;
    });
  };

  return (
    <SectionSombre>
      <ConteneurSombre>
        {faqItems.map((item, index) => (
          <ÉlémentSombre key={index}>
            <TitreSombre onClick={() => toggleItem(index)}>
              {item.question} <span>{openItems[index] ? "–" : "+"}</span>
            </TitreSombre>
            <TexteSombre isOpen={openItems[index]}>{item.answer}</TexteSombre>
          </ÉlémentSombre>
        ))}
      </ConteneurSombre>
    </SectionSombre>
  );
};
export default FAQ;